@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700&display=swap');

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255,255,255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: #052E76;
    --background-rgb: #052E76;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

.serif{
  font-family: 'Noto Serif JP', serif;
}